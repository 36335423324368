<template>
  <div class="padding">
    <h2 class="text-center">{{ info.title }}</h2>
    <div class="margin-t" v-html="info.content"></div>
  </div>
</template>

<script>
export default {

  data: function () {
    return {
      tag: "notice",
      id: 1,
      info: {},
    }
  },
  created() {
    this.load();
  },




  watch: {
    $route() {
      this.load();
    }
  },

  methods: {

    load() {
      this.tag = "notice";
      this.id = this.$route.query.id || 1;
      let info = this.$store.getters.getMedia(this.tag, this.id);
      if (info) {
        this.info = info;
        this.$nextTick(() => {
          this.$bus.$emit("scrollTop", 0);
        })
      } else {
        this.query()
      }
    },


    goBack() {
      this.$router.back();
    },
    query() {
      this.$http.post("/api/" + this.tag + "/info", { id: this.id }).then((info) => {
        this.$store.commit("saveMedia", [this.tag, info])
        this.info = info;
        this.$nextTick(() => {
          this.$bus.$emit("scrollTop", 0);
        })
      })
    },
  },
};
</script>

<style scoped>
.aside {
  position: fixed;
  height: 670px;
  margin-top: 40px;
  left: 50%;
  margin-left: 295.5px;
  top: 80px;
}

.aside>>>.el-card__body {
  padding: 10px 20px;
}

.media-box {
  width: 800px;
  margin: 40px auto;
  position: relative;
  overflow-x: hidden;
}

.media-box>>>.el-card {
  border: none !important;
}

/* 
.media-box>>>.el-card__body {
  padding: 0;
} */

.hover:hover {
  cursor: pointer;
  border: 1px solid #f2f2f2;
  box-shadow: #cccccc 0px 0px 6px 4px;
}
</style>
